
import { defineComponent, ref, computed } from '@vue/composition-api'
import { mdiCheck } from '@mdi/js'

import CommonNewPasswordInput from '@/components/common/CommonNewPasswordInput.vue'

import { useNotify } from '@/store'

import { User } from '@/api/types/user'

import { meApi } from '@/api'

import { ValidationForm } from '@/utils/types/validation'
import { isRequired } from '@/utils/validation'

export default defineComponent({
  name: 'ChangePasswordCard',
  components: {
    CommonNewPasswordInput,
  },
  props: {
    resetToken: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(_, { root }) {
    const currentUser = computed<User | null>(() => root.$store.state.auth.currentUser)
    const { addNotification } = useNotify()
    const { changePassword: changePasswordRequest } = meApi.useChangePassword()

    const changePasswordForm = ref<ValidationForm | null>(null)

    const isFormValid = ref(true)

    const newPassword = ref('')
    const oldPassword = ref('')

    const changePassword = () => {
      changePasswordRequest(newPassword.value, oldPassword.value)
        .then(() => {
          window.location.reload()

          addNotification({
            type: 'success',
            text: root.$t('changePassword.success') as string,
          })
        })
        .catch((error) => {
          error.userMessage = root.$t('profile.changePassword.error')
          throw error
        })
    }
    const isUserLocal = () => {
      return currentUser.value?.source === 'local'
    }

    return {
      icons: { mdiCheck },
      changePasswordForm,
      isRequired,
      oldPassword,
      newPassword,
      changePassword,
      isUserLocal,
      isFormValid,
    }
  },
})
