var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isUserLocal() ? _c('v-card', {
    staticClass: "rounded-lg elevation-0",
    attrs: {
      "width": "1000"
    }
  }, [_c('v-card-title', {
    staticClass: "text-h4 secondary primary--text"
  }, [_vm._v(" " + _vm._s(_vm.$t('changePassword.title')) + " ")]), _c('v-card-text', {
    staticClass: "pt-4"
  }, [_c('v-form', {
    ref: "changePasswordForm",
    attrs: {
      "id": "change-password-form"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.changePassword.apply(null, arguments);
      }
    },
    model: {
      value: _vm.isFormValid,
      callback: function callback($$v) {
        _vm.isFormValid = $$v;
      },
      expression: "isFormValid"
    }
  }, [_c('v-row', {
    staticClass: "pt-4",
    attrs: {
      "dense": ""
    }
  }, [!_vm.resetToken ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "required",
    attrs: {
      "autocomplete": "current-password",
      "type": "password",
      "rules": [_vm.isRequired(_vm.oldPassword, _vm.$t('form.field.oldPassword'))],
      "label": _vm.$t('form.field.oldPassword'),
      "hint": _vm.$t('form.hint.oldPassword'),
      "outlined": ""
    },
    model: {
      value: _vm.oldPassword,
      callback: function callback($$v) {
        _vm.oldPassword = $$v;
      },
      expression: "oldPassword"
    }
  })], 1) : _vm._e()], 1), _c('CommonNewPasswordInput', {
    staticClass: "required",
    model: {
      value: _vm.newPassword,
      callback: function callback($$v) {
        _vm.newPassword = $$v;
      },
      expression: "newPassword"
    }
  })], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "elevation": "0",
      "color": "primary",
      "disabled": !_vm.isFormValid,
      "type": "submit",
      "form": "change-password-form"
    }
  }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiCheck) + " ")]), _vm._v(" " + _vm._s(_vm.$t('form.action.changePassword')) + " ")], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }